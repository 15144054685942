.main-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.theme1-view {}

.theme2-view {
  border-radius: 10px;
}

.addSubView {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.main-view .count-text {
  font-size: 15px;
  font-family: GilroySemiBold;
  text-align: center;
}

.main-view .add-sub-text {
  font-size: 20px;
  font-family: GilroySemiBold;
  text-align: center;
}
.theme2-view .count-text {
  font-size: 13px;
}

.theme2-view .add-sub-text {
  font-size: 25px;
}
