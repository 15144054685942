.main-view,
.header-view,
.main-view .content-view {
  width: var(--view-width);
}

.main-view {
  max-height: 100vh;
  overflow: hidden;
}

.header-view {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}

.topView {
  box-shadow: 1px 1px 18px 9px #e6e6e1 !important;
}

.dealView {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cfd8e8;
  width: 90px !important;
  height: 35px !important;
  border-radius: 5px;
  cursor: pointer;
}

.dealText {
  font-family: GilroySemiBold !important;
  font-size: 15px !important;
}

.right-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.rest-closed-text {
  color: red;
  font-family: GilroyBold !important;
  text-align: right;
  margin: 0 4px !important;
}

.rest-closed-text.font-size {
  font-size: 13px;
}

.searchView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px !important;
  height: 35px !important;
  border-radius: 5px;
  cursor: pointer;
}

.menu-list-main-view {
  position: sticky;
  left: 0;
}

.main-view .content-view {
  position: relative;
  overflow-y: auto;
}

.categoryList {
  flex-wrap: nowrap !important;
  overflow-x: auto;
}

.categoryListText {
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: GilroySemiBold !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  text-align: center;
}

.filterList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterItemList {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterItemText {
  font-family: GilroySemiBold !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  cursor: pointer;
}

.menuMainTitle {
  font-family: GilroyBold !important;
  font-size: 18px !important;
  text-transform: uppercase !important;
  top: -1px;
  z-index: 6;
}

.menuSubTitleView {
  flex: 1;
}

.menuSubTitle {
  font-family: GilroySemiBold !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

.menuListItem {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #cccccc; */
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
}

.menu-item-view {
  flex: 1;
}

.dish-notes-main-view {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4px;
}

.dishNoteView {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 15px;
  border-radius: 2px;
  margin-bottom: 0 !important;
  width: fit-content;
  margin-top: 4px;
  margin-right: 4px;
  padding: 0 5px;
}

.dishNoteText {
  font-family: GilroyMedium !important;
  font-size: 9px !important;
  text-transform: uppercase;
}

.menu-item-view .menu-name-text {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  margin-right: 4px;
  word-break: break-word;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.main-view .menu-desc-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  word-break: break-word;
}

.menu-item-view .menu-price-text {
  font-family: Inter;
  font-weight: 600;
  font-size: 17px;
}

.menuRightView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-img-view {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.menuImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.menu-img-icon-view {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
}

.noItems {
  font-size: 15px;
  font-family: GilroyLight !important;
  text-align: center;
}

.menu-add-sub-btn {
  z-index: 5;
}

.menuAddView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 35px;
  cursor: pointer !important;
  z-index: 5;
}

.menuAddText {
  font-family: GilroyMedium !important;
  font-size: 15px !important;
}

.bottomView {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 1px 5px 1px #e6e6e1 !important;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.text1 {
  color: #555555;
  font-family: GilroyMedium !important;
  font-size: 10px !important;
  text-align: center;
}

.viewCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.viewCartText {
  font-family: GilroyMedium !important;
  font-size: 15px !important;
  line-height: 15px !important;
}

.viewCartCount {
  font-family: GilroyMedium !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

@media (max-width: 400px) {
  .rest-closed-text.font-size {
    font-size: 10px;
  }
}

@media (max-width: 315px) {
  .rest-closed-text.font-size {
    font-size: 8px;
  }
}
