.selectView {
  min-width: 120px !important;
  border: 1px solid #cfd8e8;
  border-radius: 5px;
  color: #000 !important;
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
}

.selectValue {
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
  text-transform: capitalize;
  align-items: center;
}
