.main-view {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: var(--view-width);
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 10px;
  gap: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.main-item-view {
  min-width: fit-content;
  height: 85%;
  /* margin-right: 10px; */
}

.item-view {
  position: relative;
  display: flex;
  align-items: flex-end;
  min-width: 100px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.main-item-view:last-child .item-view {
  /* margin-right: 10px; */
}

.img-view {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.item-img {
  width: 100%;
  height: 100%;
}

.bg-shade {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.item-view .item-text {
  position: relative;
  color: #ffffff;
  font-size: 18px;
  font-family: GilroyBold;
  white-space: nowrap;
  padding: 5px 10px;
  z-index: 3;
}

/* .category-list {
    display: 'flex';
    flex-direction: 'column';
    gap: '8px';
    padding: '8px';
    background-color: 'rgba(255, 255, 255, 0.05)';
  } */

.placeholder-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2f5831;
  color: #ffffff;
  font-size: 24px;
}

.category-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
}

.category-item {
  min-height: 80px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.category-item:hover {
  transform: scale(1.02);
}
