.cartListItem {
	border-radius: 10px;
	border: 1px solid #dde5ee;
}

.cartTitleView {
	display: flex;
	align-items: center;
	min-height: 40px;
	padding: 10px;
}

.itemTitleView {
	flex: 1;
}

.itemTitle {
	font-family: Inter !important;
	font-weight: 600 !important;
	text-transform: capitalize;
}

.statusExpandView {
	display: flex;
	align-items: center;
}

.itemStatus {
	font-family: Inter !important;
	font-weight: 600 !important;
	font-size: 10px !important;
	text-transform: uppercase;
  padding: 0 5px;
  border-radius: 3px;
}

.cartView {
	border-top: 1px solid #cccccc;
}

.itemView {
	display: flex;
	align-items: center;
	border-bottom: 1px dashed #cccccc;
	padding: 10px;
}

.itemDataView {
	flex: 1;
}

.itemName {
	font-family: Inter !important;
	font-weight: 600 !important;
}

.noteText {
	font-family: GilroySemiBold !important;
	font-size: 15px !important;
}

.itemPrice {
	font-family: Inter !important;
	font-weight: 600 !important;
}

.itemQtyView {
	// min-width: 80px;
}

.itemQty {
	color: #1d3153;
	font-family: GilroyBold !important;
	font-size: 15px !important;
	text-align: center;
}

.itemTotalQtyView {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
	padding: 10px;
	.itemTotalView {
		flex: 1;
		.itemTotal {
			font-family: Inter !important;
      font-weight: 700 !important;
			font-size: 16px !important;
      text-align: right;
		}
	}
	.itemQtyView1 {
    flex: 1;
		.itemQty1 {
			font-family: Inter !important;
      font-weight: 500 !important;
			font-size: 16px !important;
			text-align: left;
		}
	}
}
