@font-face {
  font-family: "GilroyBlack";
  src: url("../fonts/gilroy/Gilroy-Black.ttf");
}

@font-face {
  font-family: "GilroyExtraBold";
  src: url("../fonts/gilroy/Gilroy-ExtraBold.ttf");
}

@font-face {
  font-family: "GilroyBold";
  src: url("../fonts/gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "GilroySemiBold";
  src: url("../fonts/gilroy/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "GilroyLight";
  src: url("../fonts/gilroy/Gilroy-Light.ttf");
}

@font-face {
  font-family: "GilroyRegular";
  src: url("../fonts/gilroy/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "GilroyMedium";
  src: url("../fonts/gilroy/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "BauhausBold";
  src: url("../fonts/bauhausStd/BauhausStd-Bold.ttf");
}

@font-face {
  font-family: "BauhausDemi";
  src: url("../fonts/bauhausStd/BauhausStd-Demi.ttf");
}

@font-face {
  font-family: "BauhausHeavy";
  src: url("../fonts/bauhausStd/BauhausStd-Heavy.ttf");
}

@font-face {
  font-family: "BauhausLight";
  src: url("../fonts/bauhausStd/BauhausStd-Light.ttf");
}

@font-face {
  font-family: "BauhausMedium";
  src: url("../fonts/bauhausStd/BauhausStd-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-VariableFont_opsz\,wght.ttf");
}

:root {
  --view-width: 100vw;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0 !important;
  overflow: visible !important;
  padding-right: 0 !important;
  background-color: #eeeeee !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ar-rest-cat-list-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.ar-rest-cat-list-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.app-restaurant-bg-shade {
  background-image: linear-gradient(0deg, #333333 0%, transparent 100%);
}

.app-restaurant-bg-shade2 {
  /*background-image: linear-gradient(0deg, #555555 0%, transparent 100%);*/
}

.app-restaurant-menu-desc {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-rest-menu-desc {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
}

.first-char-capital::first-letter {
  text-transform: capitalize;
}

.app-restaurant-menu-title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing-ellipsis-text {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#arWebAddAddressPhInput .react-tel-input .selected-flag .arrow {
  display: none !important;
}

.bg-black-shade {
  background: linear-gradient(
    142.38deg,
    #000000 6.74%,
    rgba(0, 0, 0, 0.879274) 16.87%,
    rgba(0, 0, 0, 0) 58.4%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.ar-menu-desc-ellipse {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*#menuModelViewer {*/
/*    --progress-bar-height: 6px;*/
/*}*/

/*model-viewer::part(default-progress-bar) {*/
/*    bottom: 0 !important;*/
/*    left: 0 !important;*/
/*    right: 0 !important;*/
/*    margin: auto !important;*/
/*    border-radius: 3px;*/
/*    width: 70%;*/
/*}*/

/*ARWebLanding Style Starts*/

#arWebLandingDiv {
  background-color: #fff;
}

#arWebLandingDiv .grid-view {
  position: relative;
}

#arWebLandingDiv .img-style {
  width: 100%;
  height: 100%;
}

#arWebLandingDiv .black-bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#arWebLandingDiv .top-view {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 14%;
  left: 0;
  right: 0;
}

#arWebLandingDiv .top-img-view {
  position: relative;
  width: 300px;
}

#arWebLandingDiv .burger-img {
  position: absolute;
  top: -30px;
  left: 30px;
  z-index: 4;
}

#arWebLandingDiv .intersect-img {
  position: absolute;
  width: 80%;
  top: 0;
  right: 0;
  z-index: 3;
}

#arWebLandingDiv .intersect-gray-img {
  position: absolute;
  width: 76%;
  top: 20px;
  left: 28px;
  z-index: 2;
}

#arWebLandingDiv .ellipse-img {
  position: absolute;
  width: 80%;
  top: 75px;
  left: 30px;
  z-index: 2;
}

#arWebLandingDiv .bottom-view {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 58%;
  z-index: 6;
}

#arWebLandingDiv .title-text {
  width: 80%;
  color: #fff;
  font-size: 30px;
  line-height: 30px;
  font-family: GilroyBold;
  text-align: center;
}

#arWebLandingDiv .pay-btn {
  display: flex;
  background-color: #ffe342;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  cursor: pointer;
  width: 70%;
}

#arWebLandingDiv .pay-btn-text {
  color: #000000;
  font-size: 15px;
  font-family: GilroyBold;
  text-align: center;
}

#arWebLandingDiv .order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 24px;
  border: 1px solid #777;
  cursor: pointer;
  width: 70%;
}

#arWebLandingDiv .view-btn-text {
  color: #fff;
  font-size: 15px !important;
  font-family: GilroyBold !important;
  text-align: center;
}

.react-tel-input .country-list .country .dial-code {
  margin-right: 6px;
}

.temp2-model-viewer {
  --progress-bar-color: none !important;
}

/*ARWebLanding Style Ends*/

.vr-screen-modal-phcode .flag-dropdown.open {
  background: transparent !important;
  background-color: transparent !important;
}

.vr-screen-modal-phcode .flag-dropdown.open .selected-flag {
  background: transparent !important;
  background-color: transparent !important;
}

.vr-screen-modal-phcode .selected-flag {
  padding: 0 0 0 7px !important;
}

.vr-screen-modal-phcode:hover,
.vr-screen-modal-phcode .selected-flag:focus {
  background-color: transparent !important;
}

.vr-screen-modal-phcode .flag-dropdown:hover,
.vr-screen-modal-phcode .flag-dropdown:focus {
  background: transparent !important;
  background-color: transparent !important;
}

.vr-screen-modal-phcode .selected-flag .flag {
  transform: scale(1.3) !important;
  margin-top: -8px !important;
}

.vr-screen-modal-phcode .selected-flag .arrow {
  display: none !important;
}

.vr-screen-modal-no-value .selected-flag {
  margin-left: -7px !important;
  margin-top: -6px !important;
}

.vr-screen-modal-phcode .invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: 46px;
  color: #de0000;
  top: 24px !important;
  font-family: BauhausMedium !important;
  background: transparent !important;
  padding: 0 !important;
}

.device-width-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.device-width {
  width: var(--view-width) !important;
}

.swipeable-drawer-modal .MuiPaper-root {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  box-shadow: none;
}

.swipeable-drawer-modal-device-width {
  width: calc(var(--view-width) - 32px);
}

.next-prev-btn-device-width {
  width: calc(var(--view-width) - 32px);
}

.order-success-btn-device-width {
  width: calc(var(--view-width) - 16px);
}

.checkout-tip-view {
  width: calc(var(--view-width) / 5.5);
  height: calc(var(--view-width) / 5.5);
}

.device-width-pct {
  width: 100%;
}

.rest-open-hrs-modal .MuiPaper-root {
  max-width: 85vw;
}

@media screen and (min-width: 1025px) {
  :root {
    --view-width: 460px;
  }

  .device-width-pct {
    width: var(--view-width);
  }

  .rest-open-hrs-modal .MuiPaper-root {
    max-width: calc(var(--view-width) - 40px);
  }
}
